import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate, Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import useBreakpoint from '../../Hooks/useBreakPoint';
import ReactDelighters from '../../Components/ReactDelighters';
import News from '../News';
import {L} from '../../AppActions';

function Landing(props) {
  const [user] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const showLoginModal = useOutletSetter('login-modal');

  if (user) {
    return <News {...props} />;
  } else {
    return (
      <Wrapper>
        <img src="/images/Logo.png" width={100} />
        <h2 style={{marginTop: 20, marginBottom: 10}}>
          {L('dealers_login_first')}
        </h2>
        <Button onClick={() => showLoginModal(true)}>LOGIN</Button>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-80px);
`;

const NavBar = styled.div`
  position: fixed;
  background-color: ${(props) => props.bgColor};
  top: 0px;
  left: 0px;
  width: 100vw;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  z-index: 1;
  box-shadow: ${(props) =>
    props.hasBorder
      ? '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)'
      : 'none'};
  transition: 200ms;
`;

const HeroBannerSection = styled.section`
  padding: 80px;
  min-height: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h2 {
    font-size: 32px;
    color: white;
  }
  & > p {
    font-size: 18px;
    color: #ccc;
  }
`;

const RowBannerSection = styled.section`
  padding: 40px 20px;

  & > .content {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > .description {
      display: flex;
      flex-direction: column;
      & > h2 {
        font-size: 32px;
        color: #888;
      }
      & > p {
        font-size: 18px;
        color: #ccc;
      }
    }
  }
`;

const FlexItemSection = styled.section`
  padding: 40px;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
`;

const FlexItem = styled.div`
  margin: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .description {
    display: flex;
    flex-direction: column;
    & > h3 {
      font-size: 24px;
      color: #888;
    }
    & > p {
      font-size: 18px;
      color: #ccc;
    }
  }
`;

export default Landing;
